<template>
  <b-card title="Edit User">
    <loading :active="isLoading" loader="bars" color="#7367f0" :is-full-page="fullPage" />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="name" plaintext />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="email" plaintext />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Role" label-for="role">
              <validation-provider #default="{ errors }" name="Role" rules="required">
                <v-select v-model="roles" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="roleOptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="iFrame URL" label-for="iframe_url">
              <validation-provider #default="{ errors }" name="iFrame URL" rules="required">
                <b-form-input id="iframe_url" v-model="iframeUrl" :state="errors.length > 0 ? false:null"
                  placeholder="iFrame URL" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Second iFrame URL" label-for="second_iframe_url">
              <b-form-input id="second_iframe_url" v-model="secondIframeUrl" placeholder="Second iFrame URL" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Third iFrame URL" label-for="third_iframe_url">
              <b-form-input id="third_iframe_url" v-model="thirdIframeUrl" placeholder="Third iFrame URL" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mr-1" type="submit" @click.prevent="validationForm">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              :to="{ name: 'users' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
// import CryptoJS from 'crypto-js'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,

    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      name: '',
      email: '',
      roles: {},
      roleOptions: [],
      iframeUrl: '',
      secondIframeUrl: null,
      thirdIframeUrl: null,
      mygmsId: '',
      required,
    }
  },
  async mounted() {
    // Get the roles data
    const roles = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/roles?token=${process.env.VUE_APP_API_TOKEN}`)
    
    const allRoles = []
    roles.data.result.forEach(role => {
        const roleData = {
            id: role.id,
            name: role.name,
        }

        allRoles.push(roleData)
    })
    this.roleOptions = allRoles
    
    // Get the user details
    const userDetails = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/user/${this.$route.params.id}?token=${process.env.VUE_APP_API_TOKEN}`)
    this.name = userDetails.data.result.name
    this.email = userDetails.data.result.email
    this.roles = { id: userDetails.data.result.role, name: _.find(allRoles, { id: userDetails.data.result.role }).name }
    this.iframeUrl = userDetails.data.result.iframe_url
    this.secondIframeUrl = userDetails.data.result.second_iframe_url
    this.thirdIframeUrl = userDetails.data.result.third_iframe_url
    this.mygmsId = userDetails.data.result.person_id

    // Hide loading
    this.isLoading = false
  },
  methods: {
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }
      
      // Submit
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('update', 'user')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Setup data
          console.log(this.roles.id)
          const data = {
            people_id: this.mygmsId,
            iframe_url: this.iframeUrl,
            second_iframe_url: this.secondIframeUrl ? this.secondIframeUrl : null,
            third_iframe_url: this.thirdIframeUrl ? this.thirdIframeUrl : null,
            roles_id: this.roles.id,
          }

          // Update user data first
          this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/user/${this.$route.params.id}?token=${process.env.VUE_APP_API_TOKEN}`, data).then(() => {
            // Redirect to status lists
            this.isLoading = false
            this.$router.replace({ name: 'users' })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Data has been updated successfully.',
                  },
                })
              })
            })
        }
      })
    },
  },
}
</script>